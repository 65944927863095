export const environment = {
  production: false,
  env: 'alpha',
  defaultauth: 'fackbackend',
  firebaseConfig: {
    apiKey: 'AIzaSyCU5VMd3-lpzaAs10eZmLdnhRGOkgrydkM',
    authDomain: 'groovy-ace-198213.firebaseapp.com',
    databaseURL: 'https://groovy-ace-198213-default-rtdb.firebaseio.com',
    projectId: 'groovy-ace-198213',
    storageBucket: 'groovy-ace-198213.appspot.com',
    messagingSenderId: '83454881566',
    appId: '1:83454881566:web:21fbb4c764c89428f7a30f',
    measurementId: 'G-TTDNT37K47',
  },
  basePathRooms_chat: 'chat_personal_inbox_new/',
  basePathUnreadStaff_chat: 'unread_staff_new/',
  api: 'https://staging.tsolife.com/api',
  host: 'staging.tsolife.com',
  basePathRooms: 'chat_personal_inbox/',
  basePathUnreadStaff: 'unread_staff/',
  maps: 'AIzaSyAPA-05Kx-Rb-B65imzmpcjItDv07pn8J8',
  oktaConfig: {
    clientSecret: '5zNi7nhRIUNuSgSf1YjcikK4KLrBPkRpopDLi7hm',
    clientId: '984c740f-cc09-4e14-b0b2-c2716e08c8f7',
  },
  externalGallery: {
    unsplash: {
      api: 'https://api.unsplash.com',
      token: 'ga4wr8LmTbcyj4CsNBoioJIF-t_FVxwPGk4C3mL2QDA',
    },
  },
  benchmarkCorpId: '1bf695e8-d904-4f54-9c0d-00438d139cac',
  civitasCorpId: '93109449-d3c8-47cb-b916-77d3471e257e',
  migFormTestCorporationId: '2135b89a-e812-4972-a0ba-b725fcd0073a',
  assisstedLivingMigFormId: '8050418b-b861-11ed-afee-0299b64a2891',
  memoryCareMigFormId: 'c61c8d16-b861-11ed-afee-0299b64a2891',
  clientSecret: '5zNi7nhRIUNuSgSf1YjcikK4KLrBPkRpopDLi7hm',
  clientId: '984c740f-cc09-4e14-b0b2-c2716e08c8f7',
  relativeTagsFieldMigForm: 'a004e6fe-b861-11ed-afee-0299b64a2891',
  brookdaleCorpId: 'e3a968e8-af1b-4871-b2de-307735864e53',
  commonwealthCorpId: '8951690d-139d-45ad-b8d6-518fcd5dbad4',
  sunriseCorpId: '12db85f2-ac27-486a-8bb2-6f36ee881712',
  maxwellCorpId: '567f6ea4-ac47-4084-bb19-7344671e9526',
  seniorResourceGroupId: '65040b8f-febb-494e-8dc8-89826974a894',
  tribuneSpreadSheetUrl:
    'https://docs.google.com/spreadsheets/d/1CNN_pFeilp8MS0SopFhh_gC5fdb_vuAhXObfOgvqy84/edit#gid=1055691707',
  base: 'https://alpha.tsolife.com/',
};
